.navigation {
    text-align: center;

    ul {
        padding: 4em 0;
        margin: 0;
        list-style: none;

        li {
            font-size: 64px;
            padding: 0;
            line-height: 1;

            a {
                padding: 0.125em 0;
            }
        }
    }
}



.navigation {
    visibility: hidden;
    display: none;
    @include transition(visibility 0s $animation-duration);

    .navigation-wrapper {
        /* all navigation content */
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        
        
        /* Force Hardware Acceleration in WebKit */
        @include transform(translateZ(0));
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        @include transition(transform $animation-duration);
        @include transition-timing-function(cubic-bezier(.86,.01,.77,.78));
    }

    .navigation-is-open & {
        display: block;
        visibility: visible;
        @include transition(visibility 0s 0s);
        
        .cd-navigation-wrapper {
            @include transform(translateX(0));
            @include transition(transform $animation-duration - 0.2s);
            @include transition-timing-function(cubic-bezier(.82,.01,.77,.78));
        }
    }
}

.navigation-trigger {
    position: fixed;
    z-index: 3;
    right: 25px;
    top: 29px;
    height: 54px;
    width: 54px;
    cursor: pointer; 
    
    @include media($mobile) {
        position: absolute;
        top: 15px;
    }

    @include media($tablet) {
        position: absolute;
        top: 15px;
    }


    /* image replacement */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;

    @include transition(transform $animation-duration - 0.2s);

    .navigation-icon {
        /* icon created in CSS */
        position: absolute;
        @include center; // mixin inside variables, move later to another place
        width: 64px;
        height: 4px;
        background-color: $black;

        &::before, &:after {
            /* upper and lower lines of the menu icon */
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%; 
            background-color: inherit;

            /* Force Hardware Acceleration in WebKit */
            @include transform(translateZ(0));
            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            @include transition(transform $animation-duration - 0.2s, width $animation-duration - 0.2s, top .3s);
        }

        &::before {
            @include transform(translateY(-300%));
        }

        &::after {
            @include transform(translateY(300%));
        }

    }

    .no-touch &:hover .navigation-icon::after {
        top: 2px;
    } 

    .no-touch &:hover .navigation-icon::before {
        top: -2px;
    } 



    .navigation-is-open & {
        /* rotate trigger when navigation becomes visible */
        
        .navigation-icon {
            background-color: transparent;
        }

        .navigation-icon::after, 
        .navigation-icon::before {
            /* animate arrow --> from hamburger to arrow */
            background-color: $black;
            @include transition(transform $animation-duration - 0.2s, width $animation-duration - 0.2s);
        }

        .navigation-icon::before {
            @include transform(rotate(45deg) translateY(0));
        }

        .navigation-icon::after {
            @include transform(rotate(-45deg) translateY(0));
        }

        .no-touch &:hover .navigation-icon::after,
        .no-touch &:hover .navigation-icon::before {
            top: 0;
        } 

    }
}
