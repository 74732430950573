$sans-serif: 'Swiss-Intl', 'Helvetica', 'Arial', sans-serif;
$serif: 'Sabon', 'Garamond', 'Times New Roman', sans-serif;


body {
    @include font-feature-settings("kern", "liga", "pnum");
    color: $black;
    font-family: $serif;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    word-wrap: break-word;
}

h1, h2, h3, h4, h5, h6 {
    text-align: center;
    line-height: 1.2;
    margin: 0;
    font-weight: normal;
}

h1, h2 {
    font-family: $serif;
}

h2 {
    font-style: italic;
}

h3, h4, h5, h6 {
    font-family: $sans-serif;
    text-transform: uppercase;
    margin-top: 4em;
    padding-bottom: 0.5em;
}

h1 {
    font-size: 64px;
    @include media ($mobile) {
        font-size: 48px;
    }
}



.issue-article {
    h1 {
        padding-bottom: 0.2em;
    }
}

h2 {
    font-size: 49px;
    @include media ($mobile) {
        font-size: 32px;
    }
}

/// ISSUE H1
.issue-header {
    h1 {
        font-size: 120px;
        line-height: 1;

        @include media ($mobile) {
            font-size: 64px;
        };
    }
}

p {
    margin:  0 0 0.5em;
    //text-indent: 1em;

    &:first-child,
    &:nth-child(2) {
        //text-indent: 0;
    }
}

blockquote {
    p {
        //text-indent: 0;
    }
}

sup {
    font-family: $sans-serif;
    font-size: 16px;
    padding: 0 1px;
    border-bottom: 3px solid #000000;
    font-style: normal; 
}

a {
    color: $black;
    text-decoration: none;
    transition: color 0.1s linear;

    &:active,
    &:focus,
    &:hover {
    //color: darken($action-color, 15%);
    }

    &:active,
    &:focus {
        outline: none;
    }
}

.logo {
    text-align: center;

    @include media($tablet) {
        max-width: 680px;
        margin: 0 auto;
    }
    
    span {
        font-family: $sans-serif;
        font-size: 36px;
        text-transform: uppercase;
        //vertical-align: middle;
        padding-left: 0.2em;
        display: inline-block;
        position: relative;
        top: 10px;

        @include media ($mobile) {
            font-size: 22px;
        };
    }
}

.caption {
    display: block;
    font-size: 16px;
    font-family: $sans-serif;
    text-align: left;
    padding: 1em 2em;
}


.abstract {
    font-size: 22px;
    font-style: italic;
}

.author {
    text-align: center;
    font-family: $sans-serif;
}

header {
    .author {
        font-size: 24px;
    }
}

.issue-no {
    font-family: $sans-serif;
    text-align: center;
    text-transform: uppercase;
}


header {
    .issue-no {
        font-size: 36px;
        padding-bottom: 1rem;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

.issue-article {
    .author {
        font-size: 28px;
        padding-top: 0.5em;
    }
}

blockquote {
    font-size: 18px;

    @include media($mobile) {
        margin-right: 0;
    }
}

.bibliography, .footnotes {
    font-family: $sans-serif;
    font-size: 16px;
}

.bibliography {
    list-style: none;
    padding: 0;

    li {
        padding-bottom: 0.5em;
    }
}

.footnotes {
    ol {
        padding: 0 20px;
        margin: 0;

        li {
            padding: 0 1em 0.5em;

            &:before {
                content: ".";
                color: #fff;        // color it in white (or whatever background you have)
                float: left;
                font-size: 20px;    // make it bigger.
                font-weight: bold;  // and bolder
                position: absolute; 
                left: 18px;         // horizontal offset
                line-height: 13px;  // vertical offset
            }
        }
    }

    .reversefootnote {
        display: none;
    }
}


.page {
    font-family: $sans-serif;
}

.published-issue-no {
    font-size: 28px;
    text-align: center;
    font-family: $sans-serif;
    padding-bottom: 4em;
}

