@import 'fonts/sabon';
@import 'fonts/swiss';

@import 'vendors/normalize';

@import 'vendors/bourbon/bourbon';
@import 'vendors/neat/neat';

@import 'layout/media';

@import 'base/base';
@import 'components/components';
@import 'layout/layout';




///////////////////////////////////
///////////////////////////////////
//CHANGE
//

header {
    background-size: 600px;
    background-repeat: no-repeat;
    background-position: center;

    @include media($mobile) {
        background-size: contain;
    }
}


