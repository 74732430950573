/**
 * @license
 * MyFonts font Build ID 3137943, 2015-12-08T10:39:56-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the site owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: SabonLTPro-Roman by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/sabon/pro-roman/
 * Copyright: Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted &#x00A9; 2008 Linotype GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype GmbH, and may not be reproduced, 
 * Licensed pageviews: 250,000
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=3137943
 * 
 * © 2015 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/2fe197");
@import url("//hello.myfonts.net/count/2fe39d");
  
@font-face {
    font-family: 'Sabon';
    src: url('fonts/2FE197_0_0.eot');
    src: url('fonts/2FE197_0_0.eot?#iefix') format('embedded-opentype'),
         url('fonts/2FE197_0_0.woff2') format('woff2'),
         url('fonts/2FE197_0_0.woff') format('woff'),
         url('fonts/2FE197_0_0.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Sabon';
    src: url('fonts/2FE39D_0_0.eot');
    src: url('fonts/2FE39D_0_0.eot?#iefix') format('embedded-opentype'),
         url('fonts/2FE39D_0_0.woff2') format('woff2'),
         url('fonts/2FE39D_0_0.woff') format('woff'),
         url('fonts/2FE39D_0_0.ttf') format('truetype');
     font-weight: normal;
     font-style: italic;
}
