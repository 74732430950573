

// Images in Articles and Pages
img {
}

.portrait, .landscape {
    margin: 2em 0;
}

.portrait {
    text-align: center;

    img {
        width: 50%;
        height: auto;
    }
}

.landscape {
    img {
        width: 100%;
    }
}

.logo-img {
    width: 48px;
    height: 48px;
    vertical-align:middle
}
