body, html {
    height: 100%;
}

.content {
    margin: 0 auto;
    max-width: 42em;
    height: 100%;
    margin-bottom: 6em;

    @include media($mobile) {
        padding: 0 1em;
    }
}

header {
    padding: 1em 0;
}

.topheader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 3px solid $black;
    background-color: #ffffff;
    z-index: 250;

    @include media($mobile) {
        position: relative;
    };

    @include media($tablet) {
        position: relative;
    };


}

.issue {
    height: 100%;
    display: flex;
    justify-content: center;
    
    
    @include media($mobile) {
        display: block;
        height: auto;
        padding: 4em 0;        
    }

    @include media($tablet) {
        display: block;
        height: auto;
        padding: 4em 0;
    }

    header {
        align-self: center;
    }
}

.issue-header {
    height: 100%;
    width: 100%;
    padding: 105px 0 187px;
    
    @include media($mobile) {
        padding: 2em 0;
    }
}

.issue-article {
    margin: 12em auto;

    @include media($mobile) {
        margin-top: 4em;
    }
}

.article {
    margin-top: 8em;

    @include media($mobile) {
        margin-top: 4em;
    }
}

footer {
    min-height: 4em;
    height: 4em;
}
